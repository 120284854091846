html {
  font: normal 400 1.5em/1.5 $Akrobat;
  overflow-y: scroll;
  cursor: default;
  position: relative;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba($yellow, .5);
}
@media all and (max-width: $small) {
  html {
    // font-size: 1.25em;
  }
}

body {
  background: $color-bg;
  color: $color-fg;
  margin: 0;
  min-height: 100vh;
  font-family: $Akrobat;
  // transition : background-color .6s;
}

/* -----  Images & Figures  --------------------------------------------- */
img {
  border-style: none;
  width: 100%;
  height: auto;
}

figcaption {
  font-size: 0.75rem;
  line-height: 1.5rem;
}
