h1, h2, h3, h4, h5, h6,
p, ul, ol, figure, pre {
  /* Reset font-sizes and normalize margins */
  font-size: inherit;
  line-height: inherit;
  margin: 0 0 1rem;
}

/* Headings */
h1, h2, h3 {
  // font-family: 'Lovelo Line Light' , Helvetica, Arial, sans-serif;
  font-weight: 700;
}

h1 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 3rem;
}

h2 {
  font-size: 1.75rem;
  line-height: 2.2rem;
  text-align: center;
}

h3 {
  font-size: 1.25em;
  line-height: 1.2;
}

h4 {
  font-size: 1em;
}

/* Links */
a {
  color: inherit;
  text-decoration: none;
  // border-bottom: 4px solid #71efac;
}


.link {
  // color: $color-link;
  // // border-bottom: 3px solid $color-link;
  // transition: all 0.2s ease-out;
  // display: inline-block;
  // font-family: $Akrobat;
  // font-weight: 700;
  // padding: 0.4em 1rem 0.4rem ;
  // white-space: nowrap;
  // font-size: 0.7rem;
  // line-height: 1;
  // // margin-bottom: 1rem;
  // position: relative;

  &:after {
    // content: '';
    //
    // width: 10px;
    // height: 10px;
    // border-radius: 5rem;
    // position: absolute;
    // right: calc(4px - .4rem);
    // bottom: calc(4px - .4rem);
    // background-color: $color-link;
    // transition: all 0.2s ease-out;
  }
  &:before {
    // content: '';
    // display: none;
    //
    // width: 10px;
    // height: 10px;
    // border-radius: 5rem;
    // position: absolute;
    // left: calc(4px - .4rem);
    // bottom: calc(4px - .4rem);
    // background-color: $color-link;
    // transition: all 0.2s ease-out;
  }
}

.link:hover {
  // color: lighten($color-link,20%);
  // border-bottom: 3px solid lighten($color-link,20%);
  // padding-right: 1.5rem;
  // padding-left: 1.5rem;
  // left:0;
  //
  // &:after {
  //   // background-color: lighten($color-link,20%);
  // }
}

a:hover, a:focus, a:active {
  // color: #560bed;
}

/* Misc */
hr {
  font-size: 1.25rem;
  font-weight: 500;
  height: 1.5rem;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  color: #71efac;
}

hr::before {
  content: "";
  position: relative;
  display: block;
  background: currentColor;
  height: 2px;
  top: calc(50% + 1px);
}

hr::after {
  content: "× × ×";
  background: #fffff8;
  position: relative;
  padding: 0 .25rem;
  top: -.25rem;
}

h1 + hr,
.intro + hr {
  /* Shift a <hr> right after an `.intro`
     or <h1> block one line up. */
  margin-top: -1.5rem;
}

i, em {
  /* Vesper Libre does not have Italics,
     so we use a the *medium* weight instead */
  font-weight: 500;
  font-style: normal;
}

b, strong {
  font-weight: 700;
}

mark {
  padding: .1em .05em;
  margin: -.1em 0;
  color: #261e66;
  background: #eafded;
}


/* -----  Kirbytext  ---------------------------------------------------- */

.intro {
  text-align: center;
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 3rem;
}

h1 + .intro {
  margin-top: -2.25rem;
}

.text h2, .text h3 {
  margin-top: 3rem;
}

.text ul, .text ol {
  margin-left: 1.5em;
}

.text hr {
  /* We use a more muted style for horizontal rules within
     the main content. */
  color: #a8a5be;
}

.text ul {
  list-style: none;
}

.text ul > li::before {
  content: "–";
  display: inline-block;
  position: relative;
  width: 1.25em;
  margin-right: -1.25em;
  left: -1.25em;
  padding-left: .25em;
}

.text ol > li {
  list-style: decimal;
}

.text pre, .text code {
  background-color: #eafded;
  font-family: Courier, monospace;
  font-size: .75rem;
}

.text code {
  margin: -.25em 0;
  padding: .25em .15em;
  position: relative;
  bottom: .05em;
}

.text pre > code {
  display: block;
  margin: 0;
  padding: .8em;
  position: static;
  bottom: auto;
  overflow-x: auto;
}

@supports (-webkit-overflow-scrolling: touch) {
  .text pre > code {
    /* Enables smoother scrolling for code sections on
       mobile (i.e. touch) devices. */
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.text kbd {
  padding: 3px 7px;
  margin: -3px 2px;
  font-size: 75%;
  line-height: 1;
  background: #e9e9e9;
  border-radius: 4px;
  box-shadow: 0 2px 0 #c9c7d4;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  position: relative;
  bottom: 2px;
}

.text blockquote {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  border-left: 2px solid #a8a5be;
  padding: .75rem;
  background: #fffffd;
}

.text blockquote :last-child {
  margin-bottom: 0;
}
