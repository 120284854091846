@font-face {
  font-family: 'Lovelo Line Light';
  src: url("../fonts/lovelo_line_light-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lovelo Black';
  src: url("../fonts/lovelo_black-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Akrobat';
  src: url("../fonts/Akrobat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Akrobat';
  src: url("../fonts/Akrobat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Akrobat';
  src: url("../fonts/Akrobat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Akrobat';
  src: url("../fonts/Akrobat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
