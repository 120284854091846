@charset "UTF-8";

*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  list-style: none;
}

article, aside, details, figcaption, figure,
footer, header, main, menu, nav, section, summary {
  /* Add correct display for IE 9- and some newer browsers */
  display: block;
}

::-moz-selection {
  background: $color-selection;
}

::selection {
  background: $color-selection;
}
