////////////
// logo / title
////////////
$legHeight: 25;
$topLegsHeight: 3*$legHeight + 30px;
$bottomLegsHeight: 3*$legHeight*1.5 + 120px;

.logo {
  align-self: center;
  font-family: $LoveloLineLight;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 18rem;
  transform: scale(.8);
  margin: auto;
  padding: 3rem 0;

  .cartes {
    color: $rose;
    font-size: 5rem;
    line-height: 1.2;
  }

  .magiques {
    font-size: 3.37rem;
    margin-top: -1.7rem;
  }

  h2 {
    font-family: $LoveloBlack;
    font-size: 0.65rem;
    line-height: 0.5;
    color: $rose;
    margin-top: -.2rem;
  }

  .legs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
    height: $topLegsHeight;

    &.bottom {
      height: $bottomLegsHeight;
    }
  }

  .circle {
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background: $black;
    position: relative;
    top: 4px;
  }

  .bar {
    width: 2.5px;
    height: 40px;
    background: $black;
  }

  .leg {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-of-type(even) {
      .bar,
      .circle {
        background: $rose;
      }
    }
  }
  $topLegPositions: 3,1,2,3,1,2,1,2,3,1,2,1,2;
  @for $i from 1 to 14 {
    .top.legs .leg:nth-child(#{$i}) {
      .bar {
        height: nth($topLegPositions,$i)*$legHeight + 30px;
      }
    }
  }
  $bottomLegPositions: 1,1,2,3,1,2,1,2,3,1,2,1,1;
  @for $i from 1 to 14 {
    .bottom.legs .leg:nth-child(#{$i}) {
      .bar {
        height: nth($topLegPositions,$i)*$legHeight*2 + 100px;
      }
    }
  }

  .bottom {
    transform: rotate(180deg);

    &.legs {
      position: relative;
      top: -.5rem;
    }
  }
}
