/*---------------------------------------------------------*/
/* ----- Buttons  -----------------------------------------*/
/*---------------------------------------------------------*/
.button {
  display: inline-block;
  padding: 0.1rem 0;
  background: transparent;
  text-transform: uppercase;
  font-family: $Akrobat;
  font-weight: bold;
  font-size: 0.65rem;
  cursor: pointer;
  text-align: left;
  // opacity: 0.45;
  transition: all 0.2s;

  span {
    display: inline-block;
    border-radius: 1rem;
    position: relative;
    left: -.41rem;
    padding: 0.1rem 0.4rem 0.2rem 0.41rem;
    transition: all 0.2s;
  }
  @media screen and (min-width: $desktop) {
    font-size: 0.85rem;
  }
}

.button:focus,
.button:hover {
  opacity: 0.8;

  span {
    background-color: $color-fg-lighter;
  }
}

.button.is-checked,
.button:active {
  opacity: 1;

  span {
    background-color: $color-fg-lightest;
    color: $color-bg;
  }
}

.button.is-checked {
  &:focus,
  &:hover {
    &:after {
      content: "× ";
      opacity: 0.3;
      width: 15px;
      position: relative;
      left: -6px;
    }
  }

  span {
    color: $white;

    &:hover {
      background-color: $color-fg;
    }
    position: relative;
  }
}

.f-sensors .button.is-checked {
  &:hover {
    &:after {
      padding-left: 3px;
      position: relative;
      top: -1px;
    }
  }
}

.button-group:after {
  content: '';
  display: block;
  clear: both;
}

.button-group .button {
  float: left;
  margin-left: 0;
  margin-right: 1px;
}
/* --------------------------------------------------------------------- */
/* -----  about button  ------------------------------------------------ */
/* --------------------------------------------------------------------- */
$color-main: $satin;
$color-active: $rose;
$color-link: #FFF;
$button-height: 27px;
$button-width: 35px;

.about_button {
  display: block;
  position: fixed;
  top: 20px;
  right: 20px;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 99;
  transition: opacity 0.25s ease;

  span {
    background: $color-active;
    border: none;
    transition: all 0.35s ease;
    cursor: pointer;
    top: -3px;
    left: 0;
    opacity: 1;
    color: $color-bg;
    font-family: Georgia;
    font-style: italic;
    font-weight: bold;
    position: relative;
    background-color: rgba($color-fg,.5);
    height: 35px;
    width: 35px;
    display: block;
    padding-left: 12px;
    border-radius: $border-radius-global;
  }
}

.modal_close_button {
  display: block;
  position: fixed;
  top: 20px;
  right: 20px;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;
  background: none;

  & > * {
    opacity: 1;
  }

  .top {
    transform: translateY(11px) translateX(0) rotate(45deg);
    background: $color-bg;
  }

  .middle {
    opacity: 0;
    background-color: transparent;
  }

  .bottom {
    transform: translateY(-11px) translateX(0) rotate(-45deg);
    background: $color-bg;
  }

  span {
    background: $color-active;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;
    // &:nth-of-type(2) {
    //   top: -3px;
    //   left: 0;
    //   opacity: 1;
    //   color: $color-bg;
    //   font-family: Georgia;
    //   font-style: italic;
    //   font-weight: bold;
    //   position: relative;
    //   background-color: rgba($color-fg,.5);
    //   height: 35px;
    //   width: 35px;
    //   display: block;
    //   padding-left: 12px;
    //   border-radius: $border-radius-global;
    // }
    &:nth-of-type(2) {
      top: 22px;
    }
  }
}
.cm-modal--about{
  .modal_close_button {
    span{
      background-color: $color-active;
    }
  }
}

#toggle-card-info {
  display: block;
  text-align: center;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba($color-fg,.5);
  height: 35px;
  width: 35px;
  display: block;
  // padding-left: 12px;
  border-radius: $border-radius-global;
  z-index: 50;
  transition: opacity 0.25s ease;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: $color-bg;
    }
  }
  top: 65px;
  right: 20px;
  // opacity: 0;
  &:hover {
    opacity: 0.7;
  }
}
/*---------------------------------------------------------*/
/* ----- Slider  ----------------------------------------- */
/*---------------------------------------------------------*/
.noUi-target {
  padding: 0;
  border-radius: 15px;
  border: none;
  box-shadow: none;
  height: 6px;
  background-color: lighten($color-fg,15%);
}

.noUi-connects {
  border-radius: 0;
}

.noUi-base:after,
.noUi-base:before {
  width: 7px;
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  display: block;
}

.noUi-base:before {
  left: -7px;
}

.noUi-base:after {
  left: 100%;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: none;
  border-radius: 3px;
  font-size: 0.65rem;
  font-weight: bold;
  background: none;
  background: $black;
  color: $white;
  padding: 0 0.35rem 0.1rem;
  text-align: center;
  white-space: nowrap;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  left: auto;
  right: 0;
  top: -5px;
  cursor: pointer;

  &.noUi-handle-lower {
    right: -10px;
  }
}

.noUi-horizontal .noUi-handle {
  background: lighten($color-fg,10%);
  border: none;
  box-shadow: none;

  &:after,
  &:before {
    display: none;
  }
  width: 15px;
  height: 15px;
  border-radius: 21px;
}

.noUi-connect {
  // background: $color-link;
  background: lighten($color-fg,40%);
  border-radius: 15px;
}

body.themed .noUi-connect {
  // background: lighten($color-fg,10%);
}
/*---------------------------------------------------------*/
/*---- info btn -------------------------------------------*/
/*---------------------------------------------------------*/
a.info-btn {
  background: $color-button-bg;
  display: block;
  position: absolute;
  right: 0.5rem;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-family: Georgia, serif;
  display: none;
}
/*---------------------------------------------------------*/
/* ------  CM bullet --------------------------------------*/
/*---------------------------------------------------------*/
.cm-bullet {
  display: flex;
  height: 9px;
  margin-right: 0.5rem;

  .bar {
    height: 2.5px;
    width: 40px;
    background: $black;
    position: relative;
    top: 3px;
  }

  .circle {
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background: $black;
    position: relative;
    left: -2px;
  }
}
/*---------------------------------------------------------*/
/*---- PLUS MINUS button ----------------------------------*/
/*---------------------------------------------------------*/
.plus-btn {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: $Akrobat;
  font-weight: bold;
  font-size: 0.65rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: $color-fg-lightest;
  opacity: 0.45;
  justify-content: space-between;
  transition: all 0.2s;
  @media screen and (min-width: $desktop) {
    font-size: 0.85rem;
  }

  &:hover {
    opacity: 0.6;

    & > div {
      opacity: 1;
    }
  }
}

.plus-btn > div {
  // order:-1;
  width: 50px;
  height: 50px;
  border: 0;
  font-size: 1.5em;
  position: relative;
}

.plus-btn > div span {
  position: absolute;
  transition: 0.3s;
  background: $rose;
}

.plus-btn > div span:first-of-type {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
}

.plus-btn > div span:last-of-type {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
}


.plus-btn > div.active span:first-of-type,
.plus-btn > div.active span:last-of-type {
  transform: rotate(90deg);
}

.plus-btn > div.active span:last-of-type {
  left: 50%;
  right: 50%;
}
