/* --------------------------------------------------------------------- */
/* ----- HEADER  ------------------------------------------------------- */
/* --------------------------------------------------------------------- */
.header {
  background-color: lighten($color-fg,8%);
  position: relative;
  z-index: 75;
  padding: 1rem $page-side-padding-right 1rem $page-side-padding-left;
}

.branding {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.85rem;
  color: rgba($color-bg,.8);
  letter-spacing: 1px;
  font-family: $LoveloBlack;

  span {
    @media all and (max-width: $medium) {
      // display: none;
    }
    font-family: $Akrobat;
    text-transform: none;
    letter-spacing: 0;
    display: inline-block;
    font-size: 0.65rem;
    font-style: italic;
  }
}

.branding a {
  border-bottom: 0;
}

.sidebar-wrapper {
  background-color: $color-fg-light;
}

.dev-preview {
  position: absolute;
  // background: $color-bg;
  color: $rose;
  top: 30px;
  right: 25px;
  z-index: 100;
  font-size: 0.5rem;
  text-transform: uppercase;
  padding: 0.1rem 0.2rem;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 2px;
  // transform: rotate(-2deg);
  // width: 30px;
  // height:15px;
  // border-radius: $border-radius-global;
}
/* --------------------------------------------------------------------- */
/* ----- MODAL CONTAINER ----------------------------------------------- */
/* --------------------------------------------------------------------- */
.home .main {
  @media screen and (min-width: $medium) {
    position: relative;
  }
}

#modal-container {
  // background-color: pink;
}

.cm-modal {

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s 0s,transform 0s 0.3s;
  // background-color: $color-bg;
  min-height: 100vh;
  @media screen and (min-width: $medium) {
    // overflow-y: scroll;
  }
  transform: translateX(-100px);

  // @media all and (min-width: $medium) {
    position: absolute;
    z-index: 99;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  // }
}

ul.cards {
  // transition: visibility 0s linear .3s, height 0s linear .3s;
}

.cm-modal--is-visible {
  .cm-modal {
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
    transition: visibility 0s linear 0s, opacity 0.5s 0s, transform 0.2s 0s;
  }

  ul.cards {
    // visibility: hidden;
    // height:0 !important;
    // transition: visibility 0s linear 0.5s, height 0s linear .5s;
    // transition: visibility 0s
  }
}

.no-scroll {
  overflow: hidden;
}
/* --------------------------------------------------------------------- */
/* ----- FILTERS ------------------------------------------------------- */
/* --------------------------------------------------------------------- */
.filters {
  .f-bloc {
    padding: 1rem;

    &.f-usage {
      background-color: $color-fg-light;
    }

    &.f-search {
      background-color: $color-fg-lighter;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        button {
          display: flex;
          align-items: center;
          margin-left: 4px;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: .3px;
        }
      }
    }
  }

  .filters-plus {
    background-color: lighten($color-fg-lightest,$lightstep);
    position: relative;

    .button:hover {
      span {
        background-color: $color-fg-lighter;
      }
    }

    .button.is-checked,
    .button:active {
      span {
        background-color: $color-fg-lighter;
      }
    }
    transform: scaleY(0);
    height: 0;
    transition: transform 0s ease;
    transform-origin: top;

    &.is-open {
      transform: scaleY(1);
      height: 100%;
      transition: transform 0.3s ease;
      // transform-origin: top;
    }
  }

  .plus-btn-wrapper {
    // text-align: center;
    background-color: $color-fg-lightest;
    // position: relative;
    // top:-2px;
    &.iwant-w {
      border-bottom: 2px solid $color-fg-lighter;
    }
  }

  .filters-plus-btn {
    background-color: $color-fg-lightest;
    color: $color-bg;
    // position: absolute;
    // right:0;
    // top:0;
  }

  .i-want-plus {
    background-color: lighten($color-fg-lightest,$lightstep);
    position: relative;

    ul {
      display: flex;
      flex-direction: column;
    }

    .button:hover {
      span {
        background-color: $color-fg-lighter;
      }
    }

    .button.is-checked,
    .button:active {
      span {
        background-color: $color-fg-lighter;
      }
    }
    transform: scaleY(0);
    height: 0;
    transition: transform 0s ease;
    transform-origin: top;

    &.is-open {
      transform: scaleY(1);
      height: 100%;
      transition: transform 0.3s ease;
      // transform-origin: top;
    }
  }

  .i-want-plus-btn {
    background-color: $color-fg-lightest;
    color: $color-bg;
    // position: absolute;
    // right:0;
    // top:0;
  }

  .filter-icon {
    width: 20px;
    margin-right: 0.8rem;

    path,
    rect {
      fill: $color-bg;
    }
  }

  .f-search {
    position: relative;

    svg {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 1.1rem;

      path {
        fill: lighten($color-fg-lightest,$lightstep);
      }
    }

    input[type="text"] {
      font-size: 20px;
      width: 100%;
      font-family: $Akrobat;
      padding: 0.2rem 0.3rem 0.2rem 45px;
      border-radius: 0;
      background-color: transparent;
      border: none;
      color: $color-bg;
      font-style: italic;
      position: relative;
      top: -4px;
      // border-bottom: 3px solid lighten($color-fg,20%);
    }

    p {
      margin-bottom: 0;
    }
  }

  .f-title {
    p {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.6rem;
      margin: 0;
      // opacity: 0.35;
      opacity: 1;
      color: $color-fg;
      letter-spacing: 1px;
      padding: 0.1rem 0 0.2rem;
    }
    padding: 0.12rem 0.5rem 0 0;
    display: flex;
    justify-content: space-between;
  }

  ul {
    margin: 0;

    li {
      position: relative;
      display: inline-block;
      float: left;
      margin: 0;
      width: 100%;

      .button {
        color: $color-bg;
        text-transform: none;
      }
    }
  }

  .f-tools {
    button {
      background: lighten($color-fg,40%);
      color: $color-fg;
      opacity: 1;
      padding: 0.2rem 0.45rem;
      border-radius: 0.1rem;
      text-transform: none;
    }
  }

  .f-price {
    ul {
      margin: 0.25rem 0.5rem 1rem 0.3rem;
      justify-content: space-between;

      li {
        display: inline-block;
      }

      li.priceLabel {
        // width: 4rem;
        opacity: 0.45;
        color: $color-bg;
        height: 22px;
        font-family: $Akrobat;
        font-weight: bold;
        font-size: 0.85rem;
        // background-color: rgba($color-fg,.2);
        text-align: left;
        border-radius: 1rem 0 0 1rem;
        width: auto;
        margin-top: 0.5rem;

        &:last-child {
          text-align: right;
          float: right;
        }

        span {
          display: block;
          position: relative;
          top: -1px;
        }
      }

      li.slider-li {
        position: relative;
      }
    }
  }
}
/* --------------------------------------------------------------------- */
/* ----- BLANK STATE --------------------------------------------------- */
/* --------------------------------------------------------------------- */
.catalog-blank-state {
  display: flex;
  justify-content: center;
  // text-align: center;
  margin: auto;
  color: $color-fg;
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  z-index: 50;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease 0.3s, opacity 0.3s ease 0s;

  .catalog-blank-state-wrapper {
    padding: 1rem 2rem 0;
    background-color: $color-bg;
    display: flex;
    flex-flow: column nowrap;
    border-radius: $border-radius-global;
    text-align: center;

    span {
      font-size: 0.6rem;
      display: inline-block;
      margin-top: 1.5rem;
    }

    a,
    button {
      color: $rose;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  svg {
    margin-bottom: 1rem;

    path {
      stroke: $color-fg-lightest;
    }
  }

  h1 {
    margin-top: 3rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 1;
  }

  p {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    margin-top: 0;
    line-height: 1;
  }

  button {
    margin-top: 0;
    color: $color-link;
    background-color: transparent;
    font-size: 0.8rem;
    font-family: $Akrobat;
  }

  &.is-shown {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s ease 0s, opacity 0.3s ease 0s;
  }
}

.themed {
  .catalog-blank-state-wrapper {
    box-shadow: 4px 4px 20px rgba(0,0,0,.2);
  }
}
/* --------------------------------------------------------------------- */
/* ----- GRID CARDS ---------------------------------------------------- */
/* --------------------------------------------------------------------- */
.main {
  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  li.card-item:not(.card-cat) {
    box-shadow: 2px 2px 2px rgba(0,0,0,.1);
    transition: box-shadow 0.2s ease;
    border-radius: 0.4rem;

    &:hover {
      box-shadow: 3px 3px 4px rgba(0,0,0,.4);

      .card-item-info {
        visibility: visible !important;
        opacity: 1 !important;
        transition: visibility 0s ease 0s, opacity 0.1s ease 0s !important;
      }
    }

    .card-quality {
      display: none;
      position: absolute;
      z-index: 1;
      top: 0;
      background: $card-qual;
      font-weight: bold;
      padding: 0.05rem 0.35rem;
      font-size: 0.7rem;
      text-transform: uppercase;
      left: -0.2rem;
      box-shadow: 2px 3px 5px rgba(0,0,0,.2);
      top: 0.5rem;
      border-radius: 0 0.2rem 0.2rem 0;
      letter-spacing: 1px;

      &:before {
        content: "";
        left: 0;
        bottom: -5px;
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0.2rem 5px 0;
        border-color: transparent darken($card-qual,20%) transparent transparent;
      }
    }

    .card-quality--visible {
      display: block;
    }

    .star {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 30px;
      height: 30px;
      display: none;

      svg {
        // box-shadow: 2px 3px 5px rgba(0,0,0,.2);
        filter: drop-shadow(2px 3px 5px rgba(0,0,0,0.1));
      }

      path {
        fill: $yellow;
      }

      &.starred {
        display: flex;
      }
    }

    .star--visible {
      &.starred {
        display: flex;
      }
    }

    a.card-link {
      display: block;
      position: relative;
      padding-top: 100%;
      // height:1rem;
      z-index: 0;
      border-bottom: 0;
      padding-top: calc(100%);
      ///// 1/4
      // padding-top: calc(100% + 40px);
      /////
      mix-blend-mode: multiply;

      .card-item-photo {
        width: 100%;
        ///// 2/4 ? <- wut ?
        // padding-top:100%;
        /////
        top: 0;
        ///// 3/4
        //bottom: 15px;
        bottom: 0;
        /////
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-clip: border-box;
        background-position: center center;
        filter: grayscale(100%);
        background-size: 60%;
      }

      .card-item-info {
        ///// 4/4
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s ease 0.3s, opacity 0.3s ease 0s;
        /////
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // opacity: .5;
        .id {
          position: absolute;
          // top: 0;
          // bottom: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          padding: 10px;
          display: flex;
          font-size: 0.6rem;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
          text-transform: uppercase;
          text-align: center;
          line-height: 1;
          letter-spacing: 0.8px;

          & > div {
            display: flex;
            align-items: center;
          }

          .card-name {
            align-self: center;
            margin-left: 5px;
            opacity: 0.5;
          }

          .card-brand {
            display: none;
          }

          .osh-icon {
            align-self: flex-end;
            opacity: 0.5;
          }

          .form-icon {
            align-self: flex-end;
            opacity: 0.5;
          }

          img {
            width: 25px;
            height: 25px;
          }
        }

        div.sensors-icon-ci {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          padding: 10px;
          display: none;

          ul {
            display: flex;
            justify-content: center;
            margin-top: 2px;

            li {
              width: 25px;
              display: flex;
              justify-content: center;
              margin-bottom: 5px;
              opacity: 0.5;

              svg {
                width: 18px;

                path {
                  fill: $color-fg;
                }
              }

              span {
                display: none;
              }
            }
          }
        }
      }

      .card-item-info--is-visble {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s ease 0s, opacity 0.3s ease 0s;
      }

      .cm-card-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0.4rem;
        background: rgba(0, 0, 0, .6);
        color: #eafded;
        letter-spacing: 0.05em;
        opacity: 0;
        -webkit-transition: 0.25s opacity;
        transition: 0.25s opacity;

        h3.title {
          margin-bottom: 0;
          text-align: center;
          width: 100%;
          padding: 0.5rem;
          font-size: 1rem;
          /* Vertically center the caption */
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          display: none;
        }

        img {
          padding: 1rem;
        }
      }

      &.card-container--is-visible {
        .cm-card-container {
          opacity: 1;
        }
      }
    }
    // &.gigante{
    //   a:hover{
    //     .cm-card-container {
    //       opacity: 0;
    //     }
    //   }
    //
    // }
  }
}

.card-cat-container {
  display: none;
}

.card-cat {
  z-index: 40;
  background-color: transparent !important;
@media screen and (min-width: $large) {
  // height: 100%;

}

  .card-cat-wrapper {
    // padding-top: 100%;
    position: relative;

    & > * {
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }

  strong {
    display: inline-block;
    background: rgba(white,.1);
    padding: 0 7px;
    text-transform: uppercase;
    font-size: 0.7rem;
    border-radius: 0.2rem;
  }

  h1 {
    text-align: left;
    font-weight: 900;
    margin-bottom: 0;
    line-height: 1;
    // font-style: 2rem;
    padding: 2rem 1rem 1rem;
  }

  h2 {
    text-align: left;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 1rem;
    padding: 2rem 1rem 1rem;
  }

  p {
    padding-left: 1rem;
    font-size: 0.8rem;
    padding-right: 3rem;
  }
}

.themed {
  li.card-item:not(.card-cat) {
    // padding:2px;
    box-shadow: none;
    // transition: .2s ease-out;
    // border:2px solid rgba(0,0,0,.1);
    border-radius: 0.4rem;

    &:hover {
      box-shadow: 3px 3px 4px rgba(0,0,0,.2);
    }
  }
}
/* --------------------------------------------------------------------- */
/* ----- SINGLE CARD --------------------------------------------------- */
/* --------------------------------------------------------------------- */
body.page-card {
  .main-card {
    // background: url("../images/card-bg.svg") top left no-repeat;
    // background-size: 320px;
    // background-position: -100px -70px;
    // max-width: 30rem;
    // margin: auto;
  }
}

.cm-card-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2*$border-radius-global;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease 0.3s, opacity.3s ease 0s;
}

.card-container--is-visible {
  .cm-card-container {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s ease 0s, opacity.3s ease 0s;
  }
}

section.c-main {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // position: relative;
  // padding-top: 1rem;
}

section.c-features {}

section.c-links {
  // position: absolute;
  // position: absolute;
  // left: 0;
  // top: 12rem;
  width: 100%;
  padding: 1rem;
  height: 100%;

  p {
    margin: 0;
    @media screen and (max-width: $small) {
      margin-bottom:1rem;
    }
  }
}

h1.c-name {
  font-family: $LoveloLineLight;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
  line-height: 1;
  margin-bottom: 1rem;
  font-size: 2rem;
  @media screen and (min-width: $large) {
    font-size: 2.5rem;
  }
}

h2.c-brand {
  text-transform: uppercase;
  font-family: $LoveloBlack;
  font-size: 1rem;
  margin: 0 0;

  @media screen and (min-width: $medium) {
    margin: 2rem 0 0;

  }
}

.c-wrap-top {
  text-align: center;
  // position: absolute;
  margin: auto;
  width: 100%;
}

h3.c-form {
  order: -3;
  display: inline-block;
  color: $color-fg;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1;
  margin: 0;
  padding: 0.5rem 1rem 0;

  @media screen and (min-width: $medium) {
    padding: 0.5rem 1rem;
  }

  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .c-form,
  .c-form-item {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }

  svg {
    width: 30px;
    margin-right: 10px;

    path {
      fill: $color-fg;
    }
  }
  text-align: center;
  margin-bottom: 0;
  @media screen and (max-width: $medium) {
    font-size: 0.9rem;
  }
}

h4.c-usage {
  // border-radius: 0.7rem;
  padding: 0.7rem 1rem;
  // background-color: white;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 0.6rem;
  font-weight: 700;
  letter-spacing: 1px;
}

img.c-form-item-icon {
  width: 25px;
  height: 25px;
  position: relative;
  z-index: 2;
  position: relative;
  left: -6px;
  top: -1px;
}

span.c-form-item-text {}

figure.c-photo {
  // margin: 2rem 0;
  padding: 1.5rem;
  width: 10rem;
  height: 10rem;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-bg;
  margin: 1.5rem;
  // mix-blend-mode: multiply;
  img {
    mix-blend-mode: multiply;
    filter: grayscale(100%);
  }

  a {
    padding-top: 0 !important;
  }
}

.c-main p {
  font-size: 0.9rem;
  @media screen and (min-width: $small) {
    font-size: 0.8rem;
  }
}

.c-cont-price-osh {
  max-width: 20rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: $small) {
    padding: 0 1rem;
  }
  @media screen and (min-width: $small) {
    position: absolute;
    top: 16rem;
  }
  @media screen and (min-width: $medium) {
    top: 14rem;

  }

  p {
    position: relative;
    top: -1rem;
    margin-bottom: 0;
    position: relative;
    top: -1rem;
    margin-bottom: 0; // border: 2px solid rgba(255,255,255,.4);
    // background: rgba(0,0,0,0.05);
    // box-shadow: 0px 0px 10px rgba(0,0,0,0.04);
  }
}

p.c-price {
  span {
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    height: 50px;
    // border: 3px solid $black;
    line-height: 1;
    padding: 0.1rem 0.5rem 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

p.c-osh {
  font-weight: bold;
  font-size: 1.2rem;
  // border: 3px solid $black;
  border-radius: 0.5rem;
  line-height: 1;
  padding: 0.1rem 0.2rem 0.2rem;
  width: 75px;
  padding: 15px 10px 12px;
  position: relative;
  z-index: 1;
  // box-shadow: -0px 0px 10px rgba(0,0,0,0.04);
}

p.not-osh {
  // border-color: rgba($black,.5);
  img {
    opacity: 0.5;
  }

  &:before {
    content: " ";
    position: absolute;
    top: -7px;
    left: 1.4rem;
    width: 3px;
    height: 117%;
    display: block;
    transform: rotate(45deg);
    background-color: $black;
    z-index: 2;
  }
}

p.c-desc {


  @media screen and (max-width: $small) {
    font-size: .8rem;
    padding:0 1rem;
  }
  text-align: left;
  padding-bottom: 2rem;
  // max-width: 35rem;
  // margin-bottom:2rem;
  // @media screen and (min-width: $small) {
  //   text-align: center;
  // }
  // @media screen and (min-width: $large) {
  //   padding: 0 4rem;
  //   font-size: 0.7rem;
  // }
}

section.c-features {
  p {
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.6rem;
    @media screen and (min-width: $small) {
      font-size: 0.6rem;
    }
  }

  p.c-f-name {
    order: 1;
    // width: 100%;
    text-align: left;
    font-weight: 900;
  }

  .c-f-carac-100,
  p.c-f-carac-0 {
    letter-spacing: 1px;
    color: rgba(0,0,0,.2);
  }

  p.c-f-carac-0 {
    // width: 30%;
    display: none;

    &.active {
      // background-color: $color-bg;
      font-weight: 900;
      display: block;
      order: 3;
      text-align: left;
    }
  }

  p.c-f-carac-100 {
    // width: 100%;
    text-align: right;
    display: none;

    &.active {
      // background-color: $color-bg;
      font-weight: 900;
      display: block;
      order: 3;
      text-align: left;
    }
  }

  div.c-f-num {
    order: 2;
    width: 100%;
    display: flex;
    height: 1rem;
    margin: 0.2rem 0;

    .blob {
      background-color: rgba(0,0,0,.1);
      width: 100%;
      margin-right: 3px;
      border-radius: 2px;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        // background-color: $color-bg;
      }
    }
  }
}

section.c-onboard {
  // text-align: center;
  // padding: 1rem;
  & > div {
    // background: rgba(0,0,0,.1);
    // border-radius: 8px;
    // text-align: center;
    // padding: 0.3rem 1rem 0.5rem;
    position: relative;
    // margin:0 .5rem;
    // margin-right: 2px;
    &:last-child {
      padding-right: 0;
      // border-radius: 0 8px 8px 0;
    }

    &:first-child {
      // border-radius: 8px 0 0 8px;
    }
  }

  p.c-onboard-title {
    // text-transform: uppercase;
    display: inline !important;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    @media screen and (min-width: $small) {
      // font-size: 0.6rem;
      margin-bottom: 1rem;
    }
    color: $black;
    font-weight: 900;
    letter-spacing: 0.3px;
    // font-family:$LoveloBlack;
  }

  p {
    margin-bottom: 1rem;
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    margin: 10px 0;
  }

  li {
    font-size: 0.6rem;
  }

  .c-onboard-item {
    color: $white;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .c-onboard-item-icon {
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 5px;
    border-radius: 50px;
    position: relative;
    z-index: 2;
  }

  span.c-onboard-item-text {
    text-transform: uppercase;
    font-size: 0.6rem;
    font-weight: 900;
    padding: 0 8px 2px;
    border-radius: 20px;
    position: relative;
    letter-spacing: 0.5px;
    z-index: 1;
    line-height: 1.35;
  }

  span.c-onboard-item-text-none {
    margin-top: 5px;
  }

  li[data-value="none"] img.c-onboard-item-icon,
  li[data-value="none"] span.c-onboard-item-text {
    // background-color: $lightgrey;
  }
}

section.c-links {
  // padding: 2rem;
  // text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media all and (min-width: $desktop) and (max-width: $desktopLarge) {
    flex-flow: column nowrap;
    justify-content: start;

    p {
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: 0.6rem;
  }

  a {
    color: $color-bg;
    letter-spacing: 1px;
  }
  svg{
    width:18px;
    height:18px;
    position: relative;
    top:3px;
    left:5px;
    path{
      fill: $color-bg;
    }
  }
}
//////////////////////
//// COLORS //////////
//////////////////////
@mixin color-by-usage($color) {
  $color-darky: darken($color,2%);
  $color-dark: darken($color,5%);
  $color-darker: darken($color,15%);
  $color-darkest: darken($color,20%);
  background-color: $color-dark;

  .main-card {
    background-color: darken($color,3%)!important;
    border-radius: 2*$border-radius-global;
  }
  // .themed {
  //   .main-card {
  //     background-color: darken($color,3%)!important;
  //   }
  // }
  h4.c-usage {
    color: $color-darker;
  }

  h3.c-form {
    color: $color-darker;

    svg * {
      fill: $color-darker;
    }
  }

  span.c-onboard-item-text {
    background-color: $color;
  }

  section.c-onboard {
    // background-color: rgba(black,.1);
    // border-radius: 0 0 $border-radius-global $border-radius-global;
    .c-connectivity {
      background-color: rgba(black,.1);
    }

    .c-communication {
      background-color: rgba(black,.15);
    }

    .c-sensors {
      background-color: rgba(black,.2);
    }
  }

  section.c-onboard p.c-onboard-title {
    color: darken($color-darkest,5%);
    opacity: 0.15;
    // font-weight:400;
  }

  span.c-onboard-item-text {
    // color: darken($color-darkest,5%);
    color: $color-bg;
  }

  .c-onboard-item svg circle,
  .c-onboard-item svg path {
    fill: $color-darkest;
  }

  figure.c-photo {
    background-color: lighten($color,5%);
  }

  .c-price span {
    background-color: $color-darky;
  }

  section.c-features p.c-f-carac-0.active,
  section.c-features p.c-f-carac-100.active {
    color: $color-bg;
  }

  p.c-osh {
    background-color: transparent;

    svg {
      path,
      polygon {
        fill: $color-darkest;
      }
    }
  }

  p.c-price {
    span {
      color: $color-fg;
    }
  }

  p.c-f-name {
    color: $color-darkest;
  }

  section.c-features div.c-f-num .blob.active {
    background-color: $color-darker;
  }

  section.c-links {
    background-color: $color-darkest;

    p {
      color: $color;
    }
    svg path{
      // fill: $color;
    }
  }

  .c-wrap-top {}

}
$usage-color-map: ( iot: $color-iot, edu: $color-edu, hea: $color-hea, wea: $color-wea, har: $color-har, pro: $color-pro );
@each $usage-cat, $usage-col in $usage-color-map {
  .#{$usage-cat}-card {
    @include color-by-usage($usage-col);
  }
}
/*--------------------------------------------------------------------- */
/* -----  ABOUT ------------------------------------------------------- */
/* -------------------------------------------------------------------- */
body.page-about {
  .main-about {
    padding: 1rem;
    max-width: 30rem;
    margin: auto;
    background-color: $color-bg;
  }
}

body.home {
  .main-about {
    padding: 1rem;
    background-color: $color-bg;
    box-shadow: 12px 12px 82px rgba(0,0,0,.5);
  }
}

.logo {
  max-width: 15rem;
  padding-bottom: 0;
}

.a-intro {
  text-align: center;
}

.a-text {
  font-size: 0.65rem;
  margin: auto;

  p {
    margin-bottom: 1rem;
  }
}

.a-credits {
  text-align: center;

  p {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  figure {
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
  font-size: 0.65rem;
  margin: auto;
  font-size: 0.5rem;
  font-weight: 700;

  img {
    width: 5rem;
    mix-blend-mode: multiply;
    position: relative;
    // left: -.5em;
  }
}
