
////////////
// colors //
////////////

$white: #f3f2ea;
$black: #1b1919;
$black: #050507;
$lightgrey: #cbcccb;
$yellow:#ffff00;

$satin: lighten(#e9e6dd,5%);
$blue: #0083C6;
$cyan:#4E8C95;
$rose: #E60668;
$green: #368863;
$lightgreen: #3FAE4D;
$orange: #EB4B44;

////////////

$color-edu: $cyan;
$color-pro: $lightgreen;
$color-wea: $orange;
$color-iot: $rose;
$color-hea: $blue;
$color-har: $green;

$usage-category-color: $green;


$color-bg:$satin;
$color-bg-dark:darken($color-bg,5%);
$color-fg:$black;

// $color-bg:$black;
// $color-fg:$satin;

$color-button-bg:$color-bg-dark;
$color-button-fg:$black;


$color-selection:$yellow;

$color-link: $rose;

$lightbase: 8%;
$lightstep: 5%;

$color-fg-light : lighten($color-fg,$lightbase);
$color-fg-lighter : lighten($color-fg,$lightbase+$lightstep);
$color-fg-lightest : lighten($color-fg,$lightbase+2*$lightstep);

////////////
// Layout //
////////////

$small: 30rem; // 480px
$medium: 40rem; // 640px
$large: 55rem; // 880px
$desktop: 75rem; // 1200px
$desktopLarge: 100rem;//1600px;

$border-radius-global: 0.2rem; /// BREAKPOINTS

$page-side-padding-left : 1rem;
$page-side-padding-right : 1rem;


////////////
/// Type ///
////////////
$LoveloLineLight: 'Lovelo Line Light', sans-serif;
$LoveloBlack: 'Lovelo Black', sans-serif;
$Akrobat: 'Akrobat', sans-serif;


//////////////
.iot{
  background-color:$color-iot!important;
}
.edu{
  background-color:$color-edu!important;
}
.hea{
  background-color:$color-hea!important;
}
.wea{
  background-color:$color-wea!important;
}
.har{
  background-color:$color-har!important;
}
.pro{
  background-color:$color-pro!important;
}
.themed{
  li.card-item:not(.card-cat){
    &.iot{
      background-color:darken($color-iot,3%)!important;
    }
    &.edu{
      background-color:darken($color-edu,3%)!important;
    }
    &.hea{
      background-color:darken($color-hea,3%)!important;
    }
    &.wea{
      background-color:darken($color-wea,3%)!important;
    }
    &.har{
      background-color:darken($color-har,3%)!important;
    }
    &.pro{
      background-color:darken($color-pro,3%)!important;
    }
  }
}

$card-qual:$yellow;
