/* --------------------------------------------------------------------- */
/* ----- Home ---------------------------------------------------------- */
/* --------------------------------------------------------------------- */
.home {
  .main-wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  .main-wrapper > * {
    flex: 1 100%;
  }

  .main-about {
    // max-width: 35rem;
  }

  .gutter-sizer {
    width: 10px;
  }

  .cards {
    margin: 10px;
  }

  .card-item,
  .grid-sizer {
    width: 100%;
    margin-bottom: 10px;
  }

  .card-item.card-cat {
    width: 100%;
    margin-bottom: 10px;

    // height:auto;
  }

  .sidebar {
    position: relative;

    .sidebar-wrapper {
      position: relative;
      z-index: 50;
    }
  }
  @media all and (min-width: $small) {
    .main-wrapper {
      display: flex;
      flex-flow: row wrap;
    }

    .main-wrapper > * {
      flex: 1 100%;
    }

    .main-about {
      // max-width: 35rem;
    }

    .gutter-sizer {
      width: 10px;
    }

    .cards {
      margin: 10px;
    }

    .card-item,
    .grid-sizer {
      width: calc(50% - 5px);

    }

    .card-item.card-cat {
      width: 100%;
      margin-bottom: 10px;
    }

    .sidebar {
      position: relative;

      .sidebar-wrapper {
        position: relative;
        z-index: 50;
      }
    }
  }
  @media all and (min-width: $medium) {
    .sidebar {
      flex: 0 10rem;
      position: relative;

      .sidebar-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 10rem;
        overflow-y: scroll;
      }
    }

    .main {
      flex: 1 auto;
    }

    .filters {
      .f-usage {
        padding-bottom: 3rem;
      }

      .f-cForm,
      .f-osh,
      .f-sensors {
        padding-bottom: 0;
      }

      .f-price {
        padding-bottom: 0.01rem;
      }
    }

    .card-item,
    .grid-sizer {
      width: calc(50% - 5px);
    }

    .card-item.card-cat {
      width: calc(100% - 10px);
    }

    .gigante {
      width: 100%;
    }

    .main-about {
      .a-content {
        padding-left: 3rem;
        padding-right: 1.5rem;

        & > * {
          text-align: left;
        }
      }
    }
  }
  @media all and (min-width: $medium) and (max-width:$large) {
    .filters .f-bloc,
    .header {
      padding-left: 0.5rem;
    };

    .filters .plus-btn-wrapper .plus-btn {
      padding-left: calc( 0.5rem + 4px);
    };
  }
  @media all and (min-width: $large) {
    .sidebar {
      flex: 0 12rem;

      .sidebar-wrapper {
        width: 12rem;
      }
    }

    .card-item,
    .grid-sizer {
      width: calc(33% - 5px);
    }

    .card-item.card-cat {
      width: calc(66%);
      height: 100%;
    }

    .gigante {
      width: calc(100%);
    }

    .main-about {
      .a-content {
        padding-left: 2rem;
        padding-right: 2rem;

        & > * {
          text-align: left;
        }
      }
    }
  }
  @media all and (min-width: $desktop) {
    .sidebar {
      flex: 0 13rem;

      .sidebar-wrapper {
        width: 13rem;
      }
    }

    .card-item,
    .grid-sizer {
      width: calc(25% - 8px);
    }

    .card-item.card-cat {
      width: calc(50% - 10px);
    }

    .gigante {
      width: calc(100%);
    }
  }
  @media all and (min-width: $desktopLarge) {
    .sidebar {
      flex: 0 20%;

      .sidebar-wrapper {
        width: 20%;
      }
    }

    .card-item,
    .grid-sizer {
      width: calc(20% - 8px);
    }

    .card-item.card-cat {
      width: calc(40% - 10px);
      height: 100%;
    }

    .gigante {
      width: calc(60% - 2px);
    }
  }
}

.main-card {
  display: flex;
  flex-flow: row wrap;
  min-height: 100vh;
  box-shadow: 12px 12px 82px rgba(0, 0, 0, 0.3);
}

.main-wrapper > * {
  flex: 1 100%;
}
@media all and (min-width: $large) {
  .main-card {
    // position: fixed;
  }
}

.cm-modal {
  // position: fixed;
}

.main-card {
  // .c-main {
  //   display: flex;
  //   flex-flow: column nowrap;
  //   justify-content: space-between;
  //   height: 100%;
  //
  h1.c-name {
    order: -1;
  }

  h2.c-brand {
    order: -2;
  }
  //
  .c-wrap-top {
    order: -4;
    // width: 100%;
    // position: absolute;
    display: flex;
    flex-flow: column;
    top: 0;
    @media screen and (min-width: $medium) {
      position: absolute;
      // display: flex;
      justify-content: space-between;
      flex-flow: row;

    }
  }
  //
  .c-features {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 2rem 0;
    @media all and (max-width: $small) {
    padding-left:1rem;
    padding-right:1rem;
    }
    & > div {
      margin-right: 0.6rem;
      margin-left: 0.6rem;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.4rem;
      width: 3.6rem;
      @media all and (max-width: $small) {
        width: 100%;
        margin-right: 0rem;
        margin-left: 0rem;
      }

      & > div {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
    // @media all and (min-width: $desktop) {
    //   & > div:first-child {
    //     margin-left: 0;
    //   }
    //
    //   & > div:last-child {
    //     margin-right: 0;
    //   }
    // }
  }
  //
  .c-main {
    display: flex;
    flex-flow: column nowrap;

    .c-top {
      width: 100%;
    @media screen and (min-width: $small) {
       padding: 0 2rem;
    }
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
    }

    .c-bottom {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }

    section.c-onboard {
      display: flex;
      width: 100%;
      flex-flow: row wrap;
      text-align: center;
      // justify-content: space-between;
      & > * {
        // width: 33%;
        width: 100%;
        padding: 0 0 1rem;
      }

      ul {
        justify-content: center;
      }
    }
    @media all and (min-width: $small) {}
    @media all and (min-width: $medium) {}
    @media all and (min-width: $large) {
      section.c-onboard {
        flex-flow: row nowrap;
        text-align: left;

        & > div {
          padding: 0.3rem 1rem 0.5rem;
        }

        ul {
          justify-content: start;
        }
      }
    }
    @media all and (min-width: $desktop) {
      flex-flow: row nowrap;

      section.c-onboard {
        flex-flow: column nowrap;

        .c-onboard-title {
          position: absolute;
          right: 1rem;
          // top:0;
        }

        & > div {
          padding: 1.3rem 1rem;
        }
      }

      .c-top {
        width: 70%;
        padding: 0 2rem;
      }

      .c-bottom {
        width: 30%;
      }
    }
    @media all and (min-width: $desktopLarge) {
      section.c-onboard {
        .c-onboard-title {
          position: absolute;
          right: 1rem;
        }
      }

      .c-top {
        width: 65%;
        padding: 0 3rem;
      }

      .c-bottom {
        width: 35%;
      }
    }
  }
}
