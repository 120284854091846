////////////
// logo / title
////////////
$legHeight: 25;
$topLegsHeight: 3*$legHeight + 30px;
$bottomLegsHeight: 3*$legHeight*1.5 + 120px;
$color-legs:white;
.anim-legs {


  .legs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
    height: $topLegsHeight;

    &.bottom {
      height: $bottomLegsHeight;
    }
  }

  .circle {
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background: lighten($color-fg,15%);
    position: relative;
    top: 4px;
  }

  .bar {
    width: 2.5px;
    height: 40px;
    background: lighten($color-fg,15%);
  }

  .leg {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:nth-of-type(even) {
      .bar,
      .circle {
        background: lighten($color-fg,20%);
      }
    }
  }
  $topLegPositions: 3,1,2,3,1,2,1,2,3,1,2,1,2;
  @for $i from 1 to 14 {
    .top.legs .leg:nth-child(#{$i}) {
      .bar {
        height: nth($topLegPositions,$i)*$legHeight + 30px;
      }
    }
  }
  $bottomLegPositions: 1,1,2,3,1,2,1,2,3,1,2,1,1;
  @for $i from 1 to 14 {
    .bottom.legs .leg:nth-child(#{$i}) {
      .bar {
        height: nth($topLegPositions,$i)*$legHeight*2 + 100px;
      }
    }
  }

  .bottom {
    transform: rotate(180deg);

    &.legs {
      position: relative;
      top: -.5rem;
    }
  }
}
